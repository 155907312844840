import { fetchEventSource } from '@microsoft/fetch-event-source';
import './App.css'
import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';


function App() {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const sessionIdRef = useRef<string>(uuidv4());

  useEffect(()=> {
  sessionIdRef.current = uuidv4();  
  }, []);
  
const setPartialMessage = (chunk:string, sources: string[] = []) => {
  setMessages(prevMessages => {
    let lastMessage = prevMessages[prevMessages.length - 1];
    if (prevMessages.length === 0 || !lastMessage.isUser) {
      return [...prevMessages.slice(0, -1), {
        message: lastMessage.message + chunk,
        isUser: false,
        sources: lastMessage.sources ? [...lastMessage.sources, ...sources] : sources
      }];
    }
    return [...prevMessages, { message: chunk, isUser: false, sources }];
  })
}


  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSendMessage(inputValue.trim());
    }
  }

  const handleSendMessage = async (message: string) => {
    setInputValue('');

    setMessages(prevMessages => [...prevMessages, { message, isUser: true }]);

    await fetchEventSource(`${process.env.REACT_APP_BACKEND_URL}/rag/stream`, {
      method: 'POST',
      openWhenHidden: true,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        input: {
          question: message, 
        },
        config: {
          configurable: {
            session_id: sessionIdRef.current
          }
        }
      }),
      onmessage(event: any): void {
        if (event.event === "data") {
          handleReceiveMessage(event.data);
      } 
    },
  })
}

interface Message {
  message: string;
  isUser: boolean;
  sources?: string[];
}

const handleReceiveMessage = (data: string) => {
  var parsedData = JSON.parse(data);
  
  if (parsedData.answer) {
    setPartialMessage(parsedData.answer.content)
  }
  if (parsedData.docs) {
    setPartialMessage("", parsedData.docs.map((doc: any) => doc.metadata.source))
  }

  }

  const formatSource = (source: string) => {
    return source.split('/').pop() || source;
  }

  return (
    <div className="min-h-screen bg-gray-800 flex flex-col">
      <header className="bg-gray-900 text-white text-center p-4">
        <img src='s-logo-scanlines.svg' alt="erste logo" className="mx-auto my-4 w-1/12 logo-shine-effect" />
      <img src="logo-large-v1.svg" alt="Logo" className="mx-auto my-4 w-1/3" />
      </header>
      <main className="flex-grow container mx-auto p-4 flex-col">
        <div className="flex-grow bg-bonds-160 shadow overflow-hidden sm:rounded-lg">
          <div className='border-b border-gray-600 p-4'>
          {messages.map((msg, index) => (
          <div key={index} className={`p-3 my-3 rounded-lg text-white ml-auto ${msg.isUser ? "bg-bonds-180" : "bg-bonds-200"} `}>
              {msg.message}
              {/* Sources */}
              {!msg.isUser && (
                <div className='text-xs'>
                <hr className='border-b mt-5 mb-5'></hr>
                {msg.sources?.map((source: string, index: number) =>(
                  <div>
                    <a
                    target="_blank"
                    download
                    href={`${"https://eam-mkt-rag.s3.eu-central-1.amazonaws.com/"}${encodeURI(formatSource(source))}`}
                    rel="noreferrer"
                    className='hover:text-teal-100'
                    >{formatSource(source)}</a>
                  </div>
                ))}
                </div>
            )}
          </div>
        )

          )}
          </div>
          <div className="p-4 bg-bonds-200">
            <textarea className="form-textarea w-full p-2 border rounded text-white bg-bonds-200 border-gray-600 resize-none h-auto"
            placeholder='Enter your question here'
            onKeyUp={handleKeyPress}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}>
            </textarea>
            <button
            className="mt-2 bg-teal-100 hover:bg-teal-50 text-white font-bold py-2 px-4 rounded"
            onClick={() => handleSendMessage(inputValue.trim())}
            >
              Send
            </button>
          </div>
        </div>
      </main>
      <footer className="bg-gray-900 text-white text-center text-sm p-4">
        Built with ❤️ by Erste Asset Management
        </footer>
      
    </div>
  );
}

export default App;
